@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&family=Roboto:wght@100;300;400;500&display=swap");

.hero-contianer {
  width: 100%;
  height: calc(100vh - 70px);
}

.hero-inner-container {
  display: flex;
  height: 100%;
}

.hero-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  /* background-color: black; */
  font-size: 42px;
  padding: 50px;
  text-align: center;
  /* background-image: url("../../images/hero-side-image.png"); */
}

.hero-main-header {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

.hero-side-image {
  flex: 1;
  background: transparent;
  display: none;
  /* background-image: url("../../images/hero-side-image.png"); */
  /* background-position: center; */
}

/* Hero comming soon gradient animation */
.hero-coming-soon {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
  color: transparent;
  background-clip: text;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 820px) {
  .hero-main-header {
    font-size: 42px;
    margin-top: -100px;
    line-height: 50px;
    font-weight: 500;
  }
  .hero-side-image {
    display: none;
  }
}
