.navbar-container {
  width: 100%;
  height: 70px;
  /* background-color: black; */
  color: white;
  font-size: 20px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-weight: 400;
  background: transparent;
}

.navbar-inner-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 20px;
}

.home-logo {
  flex: 1;
  padding: 0 40px;
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 24px;
  /* align-items: baseline; */
  cursor: pointer;
}

.navbar-inner-container > ul {
  display: flex;
  flex: 5;
  gap: 25px;
  padding: 0 20px;
}

.navbar-inner-container > ul > li {
  list-style: none;
  cursor: pointer;
}

.navbar-search {
  padding: 0 50px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.responsive {
  display: none;
}

@media (max-width: 820px) {
  .navbar-inner-container > ul {
    display: none;
  }
  .home-logo,
  .navbar-search {
    padding-top: 20px;
  }

  .navbar-search {
    display: none;
  }
  .responsive {
    display: block;
  }
  .navbar-menu {
    padding-top: 20px;
    padding-right: 40px;
    cursor: pointer;
  }
}
